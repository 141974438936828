<script lang="ts">
  import Head from '$lib/Head.svelte'
  import Header from './Header.svelte'
  import D3Way from '$lib/components/d3svelte/D3Way.svelte';
  import DiscordClick from '$lib/DiscordClick.svelte'
  // import UsaHeatmap from '$lib/components/Maps/UsaHeatmapPro.svelte'
  import AnimatedBarChart from '$lib/components/Basics/AnimatedBarChart.svelte'
  export let data
</script>

<Head title="Data Visualization with Sveltekit and D3" />
<Header />

> These tutorial series requires a basic understanding of `Svelte/kit`, `D3.js` and `SVGs`.

## Getting Started with Data Visualizations using Svelte and D3

In this tutorial series, you will learn how to create visually appealing charts, graphs, animations and more using the powerful combination of Svelte and D3!. Get started by following along the tutorials.

Here is an example visualization you can create using the power of Svelte 5 and D3js.

<!-- <UsaHeatmap  countyData={data.countyData} statesData={data.statesData} animateOnMount={true}></UsaHeatmap> -->

<AnimatedBarChart></AnimatedBarChart>

## Why Svelte and D3?

While it is perfectly fine to create visualizations using D3.js without using any framework `Svelte` makes it easier to create complex visualizations with `D3`.

In its simplest form, the following code written in classical D3 way

```svelte
<script>
  import { select, selectAll } from 'd3-selection';
  import { onMount } from 'svelte';

  const data = [10, 20, 30, 40, 50];

  onMount(() => {
    let svg = select('#chart-container')
      .append('svg')
      .attr('viewBox', '0 0 600 60');

    svg
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .style('fill', 'white')
      .attr('cx', (d) => d * 8)
      .attr('cy', 20)
      .attr('r', 20);
  });
</script>

<div id="chart-container" />
```

which creates the following example:

<D3Way />

written in Svelte way becomes:

```svelte
<script>
  const data = [10, 20, 30, 40, 50];
</script>

<div id="chart-container">
  <svg viewBox="0 0 600 60">
    {#each data as d}
      <circle cx={d * 8} cy="20" r="20" fill="white" />
    {/each}
  </svg>
</div>
```

Here, data binding and creating the iteration is achieved by using the Svelte `{#each}` blocks.

In the following tutorials, we will combine the powers of Svelte and D3 to create various types of interactive charts starting from the basic bar, line and scatter charts and gradually adding animations or interactive elements and then moving to different types of custom data visualizations.

From D3 we will be using shape functions such as `d3.line`, `d3.pie` etc as well as `scales` and `interpolators`. For interactivity and animations, we'll leverage Svelte features, including `transitions` and `tween` functions. For responsiveness and updating the charts, we will use reactivity of Svelte to make our chart elements reactive to changes in the data as well is the page dimensions.

<DiscordClick />
