<script>
  import { select, selectAll } from 'd3-selection';
  import { onMount } from 'svelte';

  const data = [10, 20, 30, 40, 50];

  onMount(() => {
    let svg = select('#chart-container')
      .append('svg')
      .attr('viewBox', '0 0 600 60');

    svg
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .style('fill', 'white')
      .attr('cx', (d) => d * 8)
      .attr('cy', 20)
      .attr('r', 20);
  });
</script>

<div id="chart-container" />
